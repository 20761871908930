import { ListItemType } from '../ListItem';
import { ListItemWithDecisionIconType } from '../ListItemWithDecisionIcon';

export type DecisionType = {
  id: string;
  code: string;
  i18n: string;
  type: string;
  context: string;
};
export const DecisionCode = {
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
};

export function findDecisionCode(
  decisions: DecisionType[],
  ...decisionIdLookups: string[]
) {
  return (
    decisions?.find((decision: DecisionType) =>
      decisionIdLookups.includes(decision.i18n),
    )?.code ?? DecisionCode.GREEN
  );
}

export function findDecisionCodeMapped(
  decisions: ListItemWithDecisionIconType[],
  ...decisionIdLookups: string[]
) {
  return (
    decisions.find((decision: ListItemType) =>
      decisionIdLookups.includes(decision.label),
    )?.decisionCode ?? DecisionCode.GREEN
  );
}
export const DecisionId = {
  LTV_REJECTED: 'decision-ltv-rejected',
  HAS_LATE_PAYMENTS: 'decision-has-late-payments',
  NOKKELTALL_HIGH_DEBT_DEGREE: 'decision-nokkeltall-high-debt-degree',
  NOKKELTALL_INSOLVENCY: 'decision-nokkeltall-insolvency',
  NOKKELTALL_LOW_LIQUIDITY: 'decision-nokkeltall-low-liquidity',
  NOKKELTALL_ACCEPTABLE_WITHOUT_PAYOUT:
    'decision-nokkeltall-acceptable-without-payout',
  LOAN_AMOUNT_ABOVE_MAX: 'decision-loan-amount-above-max',
  LOAN_MAX_TOO_LOW: 'decision-loan-max-too-low',
  LTV_TOO_HIGH: 'decision-ltv-too-high',
  DOES_NOT_HAVE_FULL_OWNERSHIP: 'decision-does-not-have-full-ownership',
  PAYOUT_REASON_OTHER: 'decision-payout-reason-other',
  LTV_DIFFERENCE: 'decision-ltv-difference',
  FORCE_TO_YELLOW: 'decision-force-to-yellow',
  IS_SYSCON_LOAN: 'decision-is-syscon-loan',
  HAS_LIEN_NO_DEBT_VEHICLE: 'decision-has-lien-no-debt-vehicle',
  HAS_OTHER_MORTGAGES: 'decision-has-other-mortgages',
  CAR_LOAN_STATED_AS_NUMBER: 'decision-car-loan-stated-as-number',
  OTHER_MORTGAGES_STATED_AS_NUMBER: 'decision-other-mortgages-stated-as-number',
  PAYOUT_AMOUNT: 'decision-payout-amount',
  STUDENT_LOAN_STATED_AS_NUMBER: 'decision-student-loan-stated-as-number',
  HAS_NON_REDEEMED_LOANS: 'decision-has-non-redeemed-loans',
  AGE_TOO_YOUNG: 'decision-age-too-young',
  AGE_TOO_OLD: 'decision-age-too-old',
  CREDIT_BLOCKED: 'decision-credit-blocked',
  CREDIT_SCORE_LOW: 'decision-credit-score-low',
  CREDIT_SCORE_PAYMENT_REMARK: 'decision-credit-score-payment-remark',
  CREDIT_SCORE_OUTSIDE: 'decision-credit-score-outside',
  OTHER_MAIN_INCOME: 'decision-kyc-other-main-income',
  GJELDSREGISTERET_HIGH_DEBT_AMOUNT:
    'decision-gjeldsregisteret-high-debt-amount',
  CREDIT_SCORE_EXTRA_ASSESSMENT: 'decision-credit-score-extra-assessment',
  DEVIATION_INCOME: 'decision-deviation-income',
  MONTHLY_DELTA: 'decision-monthly-delta',
  RETIREE_AND_AGE: 'decision-retiree-and-age',
  NOT_EMPLOYEE: 'decision-not-employee',
  NOT_ACCEPTED_WORK_STATUS: 'decision-not-accepted-work-status',
  EGENERFARING_MEDIUM: 'decision-egenerfaring-medium',
  EGENERFARING_BAD: 'decision-egenerfaring-bad',
  OTHER_INCOME_RENTAL: 'decision-other-income-rental',
  KYC_LIST_HIT: 'decision-kyc-list-hit',
  KYC_BORN_OUTSIDE: 'decision-kyc-born-outside',
  KYC_MULTIPLE_CITIZENSHIP: 'decision-kyc-multiple-citizenship',
  PAYS_TAX_TO_ANOTHER_STATE: 'decision-pays-tax-to-another-state',
  KYC_EXPECTING_PAYMENTS_FROM_ABROAD:
    'decision-kyc-expecting-payments-from-abroad',
  IS_PEP: 'decision-is-pep',
  HAS_ACTIVE_APPLICATIONS: 'decision-has-active-applications',
  HAS_ACTIVE_SUBMITTED_APPLICATIONS: 'decision-has-other-active-applications',
  DUPLICATE_PHONE_NUMBERS: 'decision-duplicate-phone-numbers',
  TOTAL_LOAN_TOO_HIGH: 'decision-total-loan-too-high',
  EQUITY_OTHER_SET: 'decision-equity-other-set',
  ASSET_HAS_UNKNOWN_USABLE_AREA: 'decision-asset-has-unknown-usable-area',
  ASSET_UNKNOWN_COOP_DEBT: 'decision-asset-unknown-coop-debt',
  ASSET_HAS_PANT_I_OPPRYDNINGSBANK: 'decision-asset-has-pant-i-opprydningsbank',
  OBJECT_NO_PROPERTIES: 'decision-object-no-properties',
  OBJECT_NUM_LIEN_HOLDER: 'decision-object-num-lien-holder',
  OBJECT_LIEN_NO_LOAN: 'decision-object-lien-no-loan',
  BUILDING_STATUS_CODE: 'decision-building-status-code',
  HAS_LIEN_PROPERTIES_NON_COLLATERAL:
    'decision-has-lien-properties-non-collateral',
  OBJECT_LOW_AREA_SCORE: 'decision-object-low-area-score',
  OBJECT_YELLOW_INTERVAL_AREA_SCORE:
    'decision-object-yellow-interval-area-score',
  HIGH_PROPERTY_VALUE: 'decision-high-property-value',
  COOP_DEBT_GREATER_THAN_PROPERTY_VALUE:
    'decision-coop-debt-greater-than-property-value',
  UNKNOWN_LTV: 'decision-unknown-ltv',
  HAS_URAADIGHET: 'decision-has-uraadighet',
  HAS_ACTIVE_DEBT_STATEMENT: 'decision-has-active-debt-statement',
  HAS_BORETT: 'decision-has-borett',
  HAS_BRUKSRETT: 'decision-has-bruksrett',
  HAS_TVANGSPANT: 'decision-has-tvangspant',
  HAS_FORKJOPSRETT: 'decision-has-forkjopsrett',
  OBJECT_OPPLAANING_LOW_AREA_SCORE: 'decision-object-opplaaning-low-area-score',
  OBJECT_OPPLAANING_YELLOW_INTERVAL_AREA_SCORE:
    'decision-object-opplaaning-yellow-interval-area-score',
  OBJECT_OPPLAANING_HAS_HOLIDAY_HOME:
    'decision-object-opplaaning-has-holiday-home',
  EQUITY_TOO_LOW: 'decision-equity-too-low',
  DOCUMENTS_EQUITY: 'decision-documents-equity',
  DOCUMENTS_PURCHASE_CONTRACT: 'decision-documents-purchase-contract',
  DOCUMENTS_E_TARIFF: 'decision-documents-e-tariff',
  RECENTLY_CREATED_CORE_ACCOUNT: 'decision-has-recently-created-core-account',
};
